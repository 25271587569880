/* NAVBAR */
/* .navBar  {
    position: fixed;
    left: 0;
    right: 0;
    top: 0 !important;
} */

.navBar li {
  margin-left: 40px;
}

.navBar ul {
  display: flex;
  align-items: center !important;

}



.navbar-light .navbar-toggler {
  border: none;
  /* border-color: rgba(0, 0, 0, .1);s */
}
.navBar a {
  color: #903519;
  font-weight: 600;
  font-size: 18px;
  transition: all 0.4s ease-in;
}

/* #active::after {
    content: '';
    width: 100%;
    height: 2px;
    background-color: #903519;
    display: block;
    transition: width 0.4s ease-in;
}

#active {
    color: #903519 !important;
} */

.navBar ul li a::after {
  content: "";
  width: 0%;
  height: 2px;
  background-color: #903519;
  display: block;
  transition: width 0.4s ease-in;
}

.navBar a:hover {
  color: #903519;
}

.navBar a:hover::after {
  width: 100%;
}
.navBar {
  background: linear-gradient( rgba(206, 91, 91, 0.4),rgba(144, 240, 231, 0.2));
  padding: 20px 0px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

/* HERO SECTION */

/* ------------------ */
.heroSection {
  background-color: black;

  position: relative !important;
  width: 100%;
  height: 80vh !important;
}

.heroSection img {
  width: 100%;
  height: 100%;
}

.heroLinks {
  position: absolute;
  bottom: 60px;
}

.heroIcons {
  color: #983719 !important;
  cursor: pointer;
  font-size: 25px;
  margin-right: 15px;
}

/* ---------------------------------------Responsive ------------------------- */

/* Subheading */
.subHeading {
  color: #903519;
  font-size: 1.25rem; /* Default size */
  margin-bottom: 16px;
  text-align: center; /* Optional: for alignment consistency */
}

/* Primary Button */
.primaryBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #903519;
  color: white;
  padding: 12px 16px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.primaryBtn:hover {
  background-color: #b04828; /* Darker shade on hover */
}

.primaryBtn img {
  margin-left: 8px; /* Space between text and image */
  width: 20px;
  height: 20px;
}
.heroHeading {
  color: #983719;
}
/* Responsive Styles */
@media (max-width: 1024px) {
  .subHeading {
    font-size: 1.125rem; /* Slightly smaller text for tablets */
  }

  .primaryBtn {
    font-size: 0.95rem; /* Adjust font size */
    padding: 10px 14px;
  }

  .primaryBtn img {
    width: 18px;
    height: 18px;
  }
}

@media (max-width: 768px) {
  .navBar {
    background: linear-gradient( rgba(144, 240, 231, 0.2),rgba(206, 91, 91, 0.4),);
    padding: 20px 0px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  .subHeading {
    font-size: 1rem; /* Smaller text for mobile devices */
  }

  .primaryBtn {
    font-size: 0.9rem; /* Smaller font size */
    padding: 8px 12px;
  }

  .primaryBtn img {
    width: 16px;
    height: 16px;
  }
}

@media (max-width: 480px) {
  .navBar {
    background: linear-gradient( rgba(144, 240, 231, 0.2),rgba(206, 91, 91, 0.4),);
    padding: 20px 0px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  .subHeading {
    font-size: 0.9rem; /* Compact text size for small devices */
    color: white;
  }
  .heroIcons {
    color: #fff !important;
    cursor: pointer;
    font-size: 25px;
    margin-right: 15px;
  }
  .primaryBtn {
    font-size: 0.85rem; /* Adjust button size further */
    padding: 6px 10px;
  }

  .primaryBtn img {
    width: 14px;
    height: 14px;
  }
}

/* Typography */
/* .heroHeading {
  color: #fff;
  font-weight: bold;
  font-size: 3.2rem;
  text-align: center;
} */

.heroHeading span.greenBackground {
  background-color: #903519;
  color: white;
  padding: 0 8px;
  border-radius: 4px;
}

/* Responsive Styles */
@media (max-width: 1024px) {
  .heroHeading {
    color: #fff;
    font-size: 2.125rem; /* Adjust font size for tablets */
  }
}

@media (max-width: 768px) {
  .heroHeading {
    color: #983719;
    font-size: 1.5rem; /* Adjust font size for mobile */
  }
}

@media (max-width: 480px) {
  .heroHeading {
    color: white;
    font-size: 1.6rem; /* Compact font size for small devices */
  }
}

/* ------------------------------
 */

/* WHAT WE DO */
.whatWeDo {
  background-image: url("../Images//tt1.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 40px 0px;
}

.afterBefore {
  position: relative !important;
}

.afterBefore::after {
  content: "";
  background-color: #cbd5e1;
  height: 2px;
  display: block;
  width: 25%;
  position: absolute;
  left: 85px;
  bottom: 10px;
}

.afterBefore::before {
  content: "";
  background-color: #cbd5e1;
  height: 2px;
  display: block;
  width: 25%;
  position: absolute;
  right: 85px;
  bottom: 10px;
}

/* PROJECTS */
.projects {
  background-color: white;
  border-radius: 10px;
  padding: 30px 20px;
  width: 90%;
  margin: 30px auto;
}

/* ABOUT US */
.aboutUs {
  background-color: #903519;
  background-image: url("../Images//teest1 (3).webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.aboutUs .container {
  border: 8px solid #903519;
  border-radius: 20px;
  padding: 30px 30px 0px;
}

/* TAKE A LOOK */
/* .takeALook {
  background-color: #903519;
  background-image: url("../Images//light.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 50px 0px;
}

.takeAlookBox {
  position: relative !important;
}

.takeAlookBox img:last-child {
  border: 6px solid #903519;
  border-radius: 20px;
}

.takeALook .girlImg {
  position: absolute !important;
  width: 150px !important;
  left: -77px;
} */
/* -------------------------- */

.takeALook {
  background-color: #903519;
  background-image: url("../Images/light.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 50px 0;
}

/* Container for the images with adjustments for positioning */
.takeAlookBox {
  position: relative !important;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

/* Styling for the last image (e.g., laptop) */
.takeAlookBox img:last-child {
  border: 6px solid #903519;
  border-radius: 20px;
  width: auto;
  height: auto;
  object-fit: cover;
}

/* Styling for the girl image */
.takeALook .girlImg {
  display: none;
  position: absolute !important;
  width: 150px !important;
  left: -20px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .takeALook {
    padding: 30px 15px;
    text-align: center;
  }

  /* Stack the images in a column for smaller screens */
  .takeAlookBox {
    flex-direction: column;
  }

  /* Adjust the position and size of the girl image */
  .takeALook .girlImg {
    display: none;
    position: relative !important;
    width: 90px !important;
    left: 0;
    margin-bottom: 1rem;
  }

  /* Adjust the size of the last image */
  .takeAlookBox img:last-child {
    width: 100%;
    height: auto;
    border-radius: 15px;
  }

  .afterBefore::after {
   display: none;
  }
  
  .afterBefore::before {
    content: "";
    background-color: #cbd5e1;
    height: 2px;
    display: none;
    width: 25%;
    position: absolute;
    right: 85px;
    bottom: 10px;
  }
}

@media (max-width: 480px) {

  .afterBefore::after {
    content: "";
    background-color: #cbd5e1;
    height: 2px;
    display: none;
    width: 25%;
    position: absolute;
    left: 85px;
    bottom: 10px;
  }
  
  .afterBefore::before {
    content: "";
    background-color: #cbd5e1;
    height: 2px;
    display: none;
    width: 25%;
    position: absolute;
    right: 85px;
    bottom: 10px;
  }
  .takeALook {
    padding: 20px 10px;
  }

  .takeALook .girlImg {
    width: 100px !important;
  }

  .takeAlookBox img:last-child {
    border-radius: 10px;
  }
}

/* ------------------------------------------------- */
/* BUILD FROM STARACH */
/* .buildFromStrach {
  background-image: url("../Images//tt1.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 50px 0px;
  opacity: 0.7;
} */
.buildFromStrach {
  background-image: url("../Images/tt1.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 50px 0;
  opacity: 1; /* Maintain the original content opacity */
  position: relative;
}

.buildFromStrach::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(213, 87, 87, 0.4); /* Black overlay with 70% opacity */
  z-index: 1;
  pointer-events: none; /* Ensure the overlay doesn't interfere with clicks */
}

/* Ensure content inside the class remains above the overlay */
.buildFromStrach > * {
  position: relative;
  z-index: 2;
}

/* --------------------------------- */

/* GET START WITH */
.getStartWith {
  background: linear-gradient(rgba(206, 91, 91, 0.6), rgba(144, 240, 231, 0.3));
  /* background-image: url("../Images//tt1.webp"); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 40px 0px;
}

.getStartWith .goldenColor {
  background-color: #983719;
  color: white;
  padding: 4px 8px !important;
  border-radius: 10px;
}

.getStartWith h3 {
  font-size: 30px;
  color: #903519;
  font-weight: bold;
  margin: 0px !important;
}

/* APP DEVLOPMNET */
.appDevlopment {
  background: linear-gradient(#903519 0%, #903519, 100%);
  background-image: url("../Images//tt1.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  color: #fff;
  padding: 40px 0px;
}

/* ARTICLES */
.articles {
  /* background-color: black !important; */
  background-image: url("../Images//tt1.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 50px 0px;
  text-align: center;
}

.articles .articleBoxes {
  width: 95%;
  margin: 10px auto;
}

.articles .articleBoxes .articleBox {
  width: calc(100% / 3 - 10px);
  border-radius: 10px;
  border: 6px solid #903519;
  background: white;
  font-size: 20px;
  padding: 20px;
  min-height: 420px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  text-align: justify;
  text-transform: capitalize;
  font-weight: bold;
}

.articles .articleBoxes .articleBox:nth-child(2) {
  border: 6px solid white;
  background-color: #903519;
}

/* ourSuccessPartners */
.ourSuccessPartners {
  padding: 40px 0px !important;
}

/* partnerImgBox */
.partnerImgBox {
  background-color: black;
  width: 90%;
  margin: 20px auto;
  border-radius: 10px;
  padding: 30px 60px;
}

.partnerImgBox img {
  max-width: 110px !important;
}

/* .digitalRealities  */
.digitalRealities {
  padding: 10px 0px 40px;
}

.digitalRealities .container {
  /* background-image: url("../Images//istockphoto-1372988361-612x612.jpg"); */
  background-color: azure;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 30px !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 30px !important;
  border-radius: 20px;
}

.digitalRealitiesBox h2 {
  color: black;
  border-bottom: 3.5px solid black;
  font-size: 35px;
}

.digitalRealitiesBox h4 {
  color: black;
  border-bottom: 2px solid black;
  font-size: 18px;
  font-weight: bold;
  display: inline-block !important;
}

.digitalRealitiesBox .col-md-4 {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 10px;
}

.digitalRealitiesBox i {
  font-size: 35px;
  margin-bottom: 13px;
}

.digitalRealitiesBox p {
  font-weight: normal !important;
}

/* .TheBestTimeToStart  */
.TheBestTimeToStart {
  background-color: black;
  background-image: url("../Images//tt1.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 40px;
}

.TheBestTimeToStart button {
  padding: 13px 30px;
  font-size: 25px;
  background: linear-gradient(#903519 0%, #903519 100%) !important;
  color: white;
  font-weight: bold;
  border: none;
  outline: none;
  border-radius: 10px;
}

.TheBestTimeToStart h2 {
  font-size: 35px;
}

hr {
  margin: 0px !important;
}

/* FOOTER */
.footer {
  overflow-x: hidden;
  background-color: #903519;
  /* background-image: url("../Images//teest1 (2).webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; */
  padding: 50px 0px;
}

.footerWrapper {
  width: 93%;
  margin: 0px auto;
  color: #ecf6ec !important;
}

.footerWrapper .col-md-6:first-child h2 {
  font-size: 28px;
  border-bottom: 3.5px solid #ecf6ec;
  padding-bottom: 10px !important;
}

.footerWrapper .col-md-6:first-child h3 {
  font-size: 33px;
  margin-top: 20px;
  font-weight: bold;
}

.footerWrapper .col-md-6:first-child button {
  font-size: 19px;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 10px;
  margin-top: 20px;
  border: none;
  outline: none;
}

.footer a {
  color: #ecf6ec;
  margin: 5px 0px;
  font-size: 15px !important;
  display: inline-block !important;
  transition: all 0.4s ease;
}

.footer a::after {
  content: "";
  width: 0%;
  height: 2px;
  background-color: #f8fafc;
  display: block;
  transition: all 0.4s ease;
}

.footer a:hover::after {
  width: 100% !important;
}

.footer a:hover {
  color: #ecf6ec;
}

.footer img {
  max-width: 100px !important;
  margin-right: 25px;
  margin-bottom: 20px;
}

.footer .socialLinks a:hover::after {
  display: none !important;
}

.footer .socialLinks a:hover {
  color: white;
}

.footer .socialLinks a {
  margin: 10px 5px;
}

.footer .socialLinks a i {
  font-size: 20px;
}

/* -------------------------------- */
/* slider */
.sliderWrapper {
  margin-top: 200px !important;
}

.swiper {
  overflow: unset !important;
}

.mySwiper .swiper-slide {
  /* background-image: url("/teest1 (3).webp"); */
  width: 319px;
  height: auto !important;
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  flex-direction: column;
  padding: 20px !important;
  /* background: linear-gradient(150deg, #903519 23%, rgb(0, 0, 0) 81%); */
  background: linear-gradient(rgba(120, 227, 216, 0.6), rgba(231, 38, 38, 0.4));
  border: 5px solid white;
  color: #903519;
  font-size: 20px;
  font-weight: 500;
}

.mySwiper .swiper-slide img {
  max-width: 200px;
  position: absolute;
  top: -180px;
  z-index: 999;
}

/* testimonial slider */
/* Base Styles for Testimonial Slider */
.TestimonialSlider .swiper-slide {
  color: #797979;
  text-align: left !important;
  background-color: #f8fafc !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 20px 20px 50px !important;
  height: 35vh !important;
  border: none !important;
  position: relative !important;
  margin-bottom: 10px;
}

/* Image Styling */
.TestimonialSlider .swiper-slide img {
  position: absolute !important;
  max-width: 90px !important;
  max-height: 100px !important;
  border-radius: 50% !important;
  padding: 10px !important;
  background-color: #f8fafc !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
  left: -46px !important;
  top: 60px !important;
}

/* Text Styles */
.TestimonialSlider .swiper-slide small {
  font-size: 14px !important;
}

.TestimonialSlider .swiper-slide p {
  font-size: 14px !important;
  color: #797979 !important;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .TestimonialSlider .swiper-slide {
    padding: 15px !important;
    height: auto !important; /* Adjust height for smaller screens */
  }

  .TestimonialSlider .swiper-slide img {
    max-width: 70px !important;
    max-height: 80px !important;
    left: -30px !important;
    top: 40px !important;
  }

  .TestimonialSlider .swiper-slide small {
    font-size: 13px !important;
  }

  .TestimonialSlider .swiper-slide p {
    font-size: 13px !important;
  }
}

@media (max-width: 480px) {
  .TestimonialSlider .swiper-slide {
    padding: 10px 15px !important;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .TestimonialSlider .swiper-slide img {
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
    margin-bottom: 10px;
    max-width: 60px !important;
    max-height: 70px !important;
  }

  .TestimonialSlider .swiper-slide small,
  .TestimonialSlider .swiper-slide p {
    font-size: 12px !important;
  }
}

.navbar-light .navbar-toggler-icon {
  width: 30px;
  border: 2px solid #903519;
  padding: 20px;
  border-radius: 10px;
  background-image: url('https://cdn-icons-png.flaticon.com/512/10080/10080458.png');
}

@media screen and (max-width: 992px) {
  .articleBoxes .articleBox {
    width: calc(100% / 2 - 30px) !important;
    margin-top: 30px !important;
  }
}

@media screen and (max-width: 767px) {
  .heroLinks {
    position: absolute;
    bottom: 98px;
  }

  .heroSection {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url(https://i.ibb.co/d0S8T3k/Home-Herobg-c2e4d53f.png),
      url(https://wolftechs.pk/_next/static/media/manVr.f5cc434b.png);
    background-size: cover, cover !important;
    background-repeat: no-repeat !important;
    background-position: bottom center, center center !important;
    height: 100vh !important;
  }
  .heroSection .heroLinks {
    position: unset !important;
    margin-top: 20px !important;
    justify-content: center !important;
  }
  .center {
    text-align: center !important;
  }
  .jcc {
    justify-content: center !important;
  }

  .digitalRealities .container .row .col-md-7 {
    text-align: center !important;
  }

  .digitalRealities .container .row .col-md-5 h1 {
    text-align: center !important;
  }

  h3 {
    font-size: 20px;
  }

  .footerWrapper .row .col-md-6 .d-flex {
    justify-content: center !important;
    margin-top: 20px !important;
  }

  h1 {
    font-size: 30px;
  }

  .subHeading::after,
  .subHeading::before {
    display: none !important;
  }

  .articleBoxes .articleBox {
    width: 100% !important;
    margin-top: 30px !important;
  }

  .appDevlopment .container .row {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .takeAlookBox {
    justify-content: center !important;
  }

  .takeAlookBox img:first-child {
    display: none !important;
  }

  .padding {
    padding: 20px !important;
  }

  .getStartWith {
    text-align: center !important;
  }

  .digitalRealities h1 {
    font-size: 50px !important;
  }

  .TheBestTimeToStart .container-fluid .row {
    text-align: center !important;
  }

  .TheBestTimeToStart .container-fluid .row .col-md-3 {
    text-align: center !important;
  }

  .TestimonialSlider .swiper-slide {
    text-align: center !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    padding: 20px !important;
  }

  .textAlign {
    text-align: left !important;
  }

  .digitalRealitiesBox .col-md-4 {
    align-items: center;
  }

  .TestimonialSlider .swiper-slide img {
    margin-bottom: 20px !important;
    position: unset !important;
  }
}

@media screen and (max-width: 400px) {
  .partnerImgBox {
    justify-content: center !important;
  }

  .partnerImgBox img {
    margin: 10px 0px !important;
  }

  .padding {
    padding: 20px !important;
  }
}
