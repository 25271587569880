.swiper {
  width: 100%;
  height: 100%;
}

.appDevlopment {
  background: linear-gradient(#903519 0%, #903519, 100%);
  background-image: url("../Images//teest1 (5).webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 40px 0px;
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 33px !important;
  background-color: transparent !important;
}

.swiper-slide img {
  display: block;
  width: 100%;
  border-radius: 30px !important;
  height: 400px;
  object-fit: fill;
  transition: all 0.4s ease;
}

.swiper-pagination {
  display: none !important;
}
/* ------------------------------ */
.sliderBox {
  position: relative;
  overflow: hidden;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
}

.sliderBox:hover {
  transform: scale(1.05);
}

.sliderBox img {
  transition: transform 0.5s ease;
}

.sliderBox:hover img {
  transform: scale(1.1);
}

.sliderBox .overly {
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: flex-end !important;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
  position: absolute;
  color: white !important;
  border-radius: 30px;
  opacity: 0;
  z-index: 999;
  transition: opacity 0.9s ease, transform 0.5s ease;
  padding: 30px !important;
}

.swiper-slide:hover .overly {
  opacity: 0.9 !important;
  transform: translateY(0);
}

/* .sliderBox .overly h4 {
  font-size: 22px;

  text-transform: uppercase;
  margin-bottom: 5px;
} */
/* 
.sliderBox .overly small {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
} */

/* ------------------------ */
.sliderBox .overly {
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: flex-end !important;
  background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
  position: absolute;
  color: white !important;
  border-radius: 30px;
  opacity: 0;
  z-index: 999;
  transition: all 0.9s;
  padding: 30px !important;
}

.swiper-slide:hover .overly {
  opacity: 0.8 !important;
}

.ourTeam {
  background-image: url("../Images/teest1 (5).webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.ourTeam {
  overflow-x: hidden !important;
}
