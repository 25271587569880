.ResellerSwiper .swiper-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px !important;
    background-color: white ;
    padding-top: 10px !important;
}

.ResellerSwiper .swiper-slide div {
    width: 100% !important;
}

.ResellerSwiper .swiper-slide small {
    display: block !important;
    font-size: 12px !important;

}

@media screen and (max-width:767px){
    .none{
        display: none !important;
    }
    .fullW{
        width: 100% !important;
    }
}