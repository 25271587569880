.Testimonials,
.whatWeDo {
  overflow-x: hidden !important;
}
body::-webkit-scrollbar {
  background-color: black !important;
  width: 6px !important;
}

body::-webkit-scrollbar-thumb {
  background-color: #903519 !important;
  border-radius: 5px !important;
}

@import url("https://fonts.cdnfonts.com/css/metro-uber-font");

.blur-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2%;
  background: rgba(206, 91, 91, 0.4);
  backdrop-filter: blur(50px);
  z-index: 1; /* Ensure it overlays correctly */
}
a {
  text-decoration: none !important;
}

li {
  list-style: none !important;
}

h1 {
  font-size: 60px;
  color: white;
  font-weight: bold;
  font-family: "Uber Bold", sans-serif;
}

h2 {
  font-size: 45px;
  color: white;
  font-weight: bold;
  font-family: "Uber Bold", sans-serif;
  display: inline-block !important;
}

.borderBottom {
  border-bottom: 3px solid #903519;
}

.greenColor {
  color: #903519;
}

.subHeading {
  font-size: 24px;
  font-weight: 600;
  color: white;
}

p {
  font-size: 15px !important;
  line-height: 1.75rem;
  font-weight: 550;
  color: white;
  font-family: "Uber Bold", sans-serif;
}

.greenBackground {
  background-color: #903519;
  padding: 2px 8px;
  border-radius: 8px;
}

.btnBox {
  width: min-content;
  border-radius: 9px;
  padding: 0px 10px;
  cursor: pointer;
  background-color: #e9e9e9;
}

.btnBox button {
  border: none;
  outline: none;
  background-color: transparent;
}

/* boost slider  */
.boostSwiper .swiper-slide {
  border-radius: 0px !important;
}
.boostSwiper .swiper-slide img {
  border-radius: 0px !important;
}
