.SmsSwiper .swiper-slide {
    padding: 20px !important;
    background-color: #ffffff !important;
    text-align: center !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    border-radius: 10px !important;
    

}

.SmsSwiper .swiper-slide p {
    color:#626262 !important;
    font-weight: normal !important;
    margin-bottom: 0px !important;

}