.contactForm {
    background-image: url('../Images/teest1 (3).webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 60px 0px !important;
}

.contactForm .col-md-10 {
    background-color: white !important;
    margin: 0px auto !important;
    padding: 50px !important;
    border-radius: 20px;
    display: flex;
    align-content: center;
    text-align: center;
    flex-direction: column;
}

.contactForm .col-md-6 {

    display: flex !important;
    align-items: start !important;
    flex-direction: column !important;

}

.contactForm label {
    font-size: 12px;
    padding-bottom: 4px !important;
}

.react-international-phone-input-container {
    width: 100% !important;
}

.react-international-phone-input {
    width: 100% !important;
}

.contactForm button:last-child {
    background-color: #903519;
    padding: 10px 20px;
    border: none;
    outline: none;
    border-radius: 8px;
    color: white;
    font-weight: bold;
}

.contactForm input::placeholder {
    font-size: 13px !important;
}

.contactForm .textarea::placeholder{
    font-size: 13px !important;
}

@media screen and (max-width:767px){
    .mtThirty{
        margin-top: 32px !important;
    }
}