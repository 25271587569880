.OurBestProjecta {
  background-image: url("../Images/tt1.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.nav-tabs .nav-link {
  margin: 0px 10px !important;
  background-color: lightgray;
  border-radius: 5px;
  color: black;
}

.nav-tabs .nav-link:hover {
  background-color: #903519;
  color: white;
}

.nav-tabs .nav-link.active {
  background-color: #903519;
  border-radius: 5px;
  color: white;
}

.OurBestProject .bestProjectsBoxes {
  width: calc(100% / 3 - 15px) !important;

  /* display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px; */
}

@media screen and (max-width: 767px) {
  .OurBestProjecta .bestProjectsBoxes {
    width: 100% !important;
    margin-bottom: 20px !important;
  }
  .nav-tabs .nav-link {
    margin: 10px !important;
  }
}
