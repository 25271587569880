.EverythingInOnePlace {
  padding: 50px 0px;
  background-image: url("../Images/teest1 (6).webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.EverythingInOnePlace button {
  padding: 10px 30px;
  border-radius: 9px;
  border: none;
  outline: none;
  background-color: #ffffff;
  font-size: 20px !important;
  margin: 10px;
  transition: 0.2s ease;
}

.EverythingInOnePlace button:hover {
  background-color: #903519;
  color: white;
}

.highQuality button:hover {
  color: black !important;
}

.EverythingInOnePlace .swiper-slide img {
  display: none !important;
}
.EverythingInOnePlace .swiper-slide button {
  background-color: #e9e9e9 !important;
  padding: 0px;
}
.EverythingInOnePlace .swiper-slide button:hover {
  background-color: unset !important;
}
.EverythingInOnePlace {
  overflow-x: hidden !important;
}
/*  */
